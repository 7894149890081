.DateRangePickerInput_arrow_svg {
  width: 50px;
}

.DateRangePickerInput.DateRangePickerInput_1.DateRangePickerInput__withBorder.DateRangePickerInput__withBorder_2 {
  width: 22vw;
  cursor: pointer;
}

.DateInput {
  width: 6vw;
}

.DateInput_input {
  font-size: 14px;
}

.DayPickerKeyboardShortcuts_show__bottomRight {
  display: none;
}

.DateRangePickerInput_clearDates__hide {
  visibility: visible;
}

.CalendarDay__selected {
  background: #00c7fc;
}

.CalendarDay__selected_span {
  background: #00c7fc;
}

.DateRangePicker_picker {
  position: unset;
}
