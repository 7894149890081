.container {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 1150px;
  max-width: 1150px;
}

.titleContainer {
  width: 1250px;
}

.truncate {
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.renderSegmentToggleButton {
  position: absolute;
  right: 0;
}

/* Media Queries for different screen sizes */
@media (max-width: 599px) {
  .container {
    max-width: 100%;
  }
  .titleContainer {
    max-width: 100%;
  }
}

@media (min-width: 600px) and (max-width: 899px) {
  .container {
    max-width: 500px;
  }
  .titleContainer {
    max-width: 400px;
  }
}

@media (min-width: 900px) and (max-width: 1249px) {
  .container {
    max-width: 700px;
  }
  .titleContainer {
    max-width: 600px;
  }
}

@media (min-width: 1250px) and (max-width: 1499px) {
  .container {
    max-width: 900px;
  }
  .titleContainer {
    max-width: 800px;
  }
}

@media (min-width: 1500px) {
  .container {
    max-width: 1250px;
  }
}
